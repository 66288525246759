<template>
  <div class="membership-person">
    <div class="membership-person__text">
      <p>
        Наверное, не каждый человек с любовью может говорить о своей работе, но
        активисты Лиги — люди другого сорта. Потому что все, что создает
        организация, развивает студентов, дает им личный, профессиональный рост,
        учит тому, о чем не прочтешь в книгах, а сможешь познать только на
        практике, прежде всего, уникальному опыту общения с самыми разными
        людьми.
      </p>
      <p>
        Если ты хочешь стать частью нашей команды, тебе нужно всего лишь подать
        заявку на членство в Лиге! Ведь Лига открыта для ВСЕХ!
      </p>
    </div>
    <div class="membership-person__info">
      <div class="membership-person__item">
        <p>ФИО</p>
        <p>{{ user?.fio }}</p>
      </div>
      <div class="membership-person__item">
        <p>Дата рождения</p>
        <p>{{ user?.birthday_format }}</p>
      </div>
      <div class="membership-person__item">
        <p>Учебное заведение</p>
        <p>{{ user?.info_educational_establishment?.title_short }}</p>
      </div>
      <div class="membership-person__item">
        <p>Академическая группа</p>
        <p>{{ user?.group }}</p>
      </div>
    </div>

    <div
      class="membership-person__agreement-body"
      v-if="currentMembershipStatus === 0"
    >
      <div class="membership-person__agreement-iem">
        <DocsUpload
          label="Студенческий билет"
          currentDocsOrientation="horizontal"
          keyName="document_student_billet"
          :file-loaded="
            user?.education?.document_files?.document_student_billet?.full
          "
        />
      </div>
      <div class="membership-person__agreement-item">
        <Checkbox label="Согласие с" v-model="charter" />
        <a target="_blank" href="https://ligastudentov.com/charter">
          Уставом Организации
        </a>
      </div>
      <div class="membership-person__agreement-item">
        <Checkbox label="Согласие на обработку" v-model="privacy" />
        <a target="_blank" href="https://ligastudentov.com/privacy">
          персональных данных
        </a>
      </div>
    </div>
    <Button
      @click="send"
      :disabled="!charter || !privacy || !formData.document_student_billet"
      v-if="currentMembershipStatus === 0"
      :loading="isBtnLoading"
    >
      Отправить заявку
    </Button>
    <p
      class="membership-person__status"
      v-if="currentMembershipStatus !== 0"
      :class="{
        'membership-person__status--member': currentMembershipStatus === 10,
      }"
      @click="refresh"
    >
      Статус:
      <span>
        {{
          currentMembershipStatus === 1
            ? "Отправлена заявка"
            : user?.info_liga_status?.title
        }}
      </span>
    </p>
  </div>
</template>

<script>
import Checkbox from "@/components/Blocks/Checkbox";
import Button from "@/components/Blocks/Button";
import { mapState } from "vuex";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import DocsUpload from "@/components/Blocks/DocsUpload";
import useVuelidate from "@vuelidate/core/dist/index.esm";
import { helpers, required } from "@vuelidate/validators";

export default {
  name: "MembershipPerson",
  components: { DocsUpload, Button, Checkbox },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      charter: false,
      privacy: false,
      isBtnLoading: false,
      formData: {
        document_student_billet: "",
      },
    };
  },
  validations() {
    let validationsObj = {};
    if (!this.user.education?.document_files?.document_student_billet?.thumb)
      validationsObj.document_student_billet = {
        required: helpers.withMessage("Обязательно к заполнению", required),
      };
    return { formData: validationsObj };
  },
  watch: {
    user() {
      this.loadInfo();
    },
    docs: {
      handler: function () {
        Object.keys(this.formData).forEach((item) => {
          return (this.formData[item] = this.docs[item]);
        });
        this.isBtnDisabled = false;
      },
      deep: true,
    },
  },
  methods: {
    async send() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      this.isBtnLoading = true;

      if (
        !this.user?.education?.document_files?.document_student_billet?.thumb
      ) {
        await this.saveDocs().then(() => {
          this.sendStatement();
        });
      } else await this.sendStatement();
    },
    loadInfo() {
      if (this.user?.education) {
        Object.keys(this.formData).forEach((item) => {
          if (
            this.user?.education?.document_files[item] &&
            this.user?.education?.document_files[item].thumb
          ) {
            return (this.formData[item] =
              this.user?.education?.document_files[item]);
          } else return (this.formData[item] = this.docs[item]);
        });
      }
    },
    saveDocs() {
      let payloadObject = {};
      Object.keys(this.formData).forEach((item) => {
        if (
          this.user?.education?.document_files[item]?.thumb &&
          this.formData[item].thumb
        ) {
          this.formData[item] = "";
        }
        if (this.formData[item]) {
          return (payloadObject[item] = this.formData[item]);
        }
      });

      return createRequest(requestConfigs.POSTSaveEducation, {
        jsonPayload: payloadObject,
      })
        .then((response) => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
          Object.keys(response.data).forEach((item) => {
            this.user.education.document_files[item] = response.data[item];
          });
          Object.keys(this.formData).forEach((item) => {
            this.formData[item] = this.user?.education?.document_files[item];
            this.docs[item] = "";
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        });
    },
    sendStatement() {
      createRequest(requestConfigs.POSTSendMembership, {
        jsonPayload: {
          liga_status_need: 1,
        },
      })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Заявка на членство успешно отправлена",
            type: "success",
          });
          this.$store.commit("user/setCurrentMembershipStatus", 1);
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    // refresh() {
    //   this.$store.commit("user/setCurrentMembershipStatus", 10);
    // },
  },

  computed: {
    fieldsInfo() {
      return { document_student_billet: "Студенческий билет" };
    },
    ...mapState("user", ["user", "currentMembershipStatus"]),
    ...mapState(["docs"]),
  },
  mounted() {
    this.loadInfo();
  },
};
</script>

<style lang="scss">
.membership-person {
  &__text {
    font-size: 1.8rem;
    line-height: 2.2rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    max-width: 650px;
    border-bottom: 1px solid var(--background--btn--profile--hover);
  }
  &__alert {
    font-size: 1.8rem;
    line-height: 2.2rem;
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
    @include link();
    p {
      color: var(--red);
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  &__item {
    & p:first-child {
      font-weight: 500;
    }
    & p:last-child {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  &__agreement-item {
    display: flex;
    flex-wrap: wrap;
    @include link();
    gap: 5px;
    & > div {
      margin: 0;
    }
    a {
      font-size: 1.4rem;
    }
  }
  &__agreement-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    max-width: 650px;
  }
  &__status {
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
    span {
      font-size: 1.6rem;
      color: var(--background--btn--red);
    }
    &--member {
      span {
        color: green;
      }
    }
  }
  @include adaptive(phone) {
    &__text {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
    &__item {
      & p:last-child {
        font-size: 1.4rem;
        line-height: 1.7rem;
      }
    }

    &__alert {
      font-size: 1.6rem;
      line-height: 1.9rem;
      flex-wrap: wrap;
    }
  }
}
</style>
